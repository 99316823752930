export enum AppRoutes {
  NO_MATCH = '*',
  MAIN = '/*',
  LEGAL = 'legal/*',
  QR = 'qr/*',
  POST_REDIRECT = 'post-redirect',
  REDIRECT = 'redirect/:operation',
  SIGNIN = 'signin',
  SIGNUP = 'signup/*',
  VERIFICATION = 'verification',
  MOBILE_APP_INFO = 'mobile-app-info',
  CPD_SIGNING = 'cpd-signing',
  ONBOARDING_DENIED = 'onboarding-denied',
}
