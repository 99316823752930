import React, { FC } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AppRoutes } from '@pages/types';

const AppPages = React.lazy(() => import('./pages/AppPages'));
const LegalPages = React.lazy(() => import('./pages/LegalPages'));
const PostRedirectPage = React.lazy(() => import('./pages/MobileAppPages'));
const QRCodePage = React.lazy(() => import('./pages/QRCodePage'));
const RedirectPages = React.lazy(() => import('./pages/RedirectPages'));
const VerificationPage = React.lazy(() => import('./pages/SignUpFlowPages/VerificationPage'));
const CPDSigningPage = React.lazy(() => import('./pages/SignUpFlowPages/CPDSigningPage'));

export const App: FC = () => (
  <React.StrictMode>
    <React.Suspense fallback={null}>
      <Router>
        <Routes>
          <Route path={AppRoutes.LEGAL} element={<LegalPages />} />
          <Route path={AppRoutes.POST_REDIRECT} element={<PostRedirectPage />} />
          <Route path={AppRoutes.QR} element={<QRCodePage />} />
          <Route path={AppRoutes.REDIRECT} element={<RedirectPages />} />
          <Route path={AppRoutes.VERIFICATION} element={<VerificationPage />} />
          <Route path={AppRoutes.CPD_SIGNING} element={<CPDSigningPage />} />
          <Route path={AppRoutes.NO_MATCH} element={<AppPages />} />
        </Routes>
      </Router>
    </React.Suspense>
  </React.StrictMode>
);
